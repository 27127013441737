<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('settings.lang_addWebhooks') }}
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-form lazy-validation ref="webhooksForm" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="name" :disabled="loading" outlined  :rules="[rules.required]" aria-autocomplete="false"
                          :label="$t('generic.lang_name')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="target" :disabled="loading" outlined  :rules="[rules.https,rules.required]" aria-autocomplete="false"
                          :label="$t('settings.lang_targetUrl')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="type" :items="types" :disabled="loading" outlined :rules="[rules.required]"
                        :label="$t('generic.lang_type')">

              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC" v-model="secretKey" :rules="[rules.required]" :disabled="loading" outlined aria-autocomplete="false"
                          :label="$t('settings.lang_secretKey')"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="{'d-block':$vuetify.breakpoint.xs,'text-right':true}">
        <v-spacer/>
        <v-btn color="success" :disabled="loading || !valid" :loading="loading" @click="saveData">{{
            $t('generic.lang_save')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import validation from "../../../../mixins/validation/validation";
import mixin from '../../../../mixins/KeyboardMixIns';
import randomString from "randomstring";

export default {
  name: "CreateWebhooksComponent",
  mixins:[validation,mixin],
  data() {
    return {
      loading: false,
      name:'',
      target:'',
      types:[
          "sale.created",
        "order.created",
        "order.edited",
        "item.created",
        "item.edited",
        "item.deleted",
        "itemgroup.created",
        "itemgroup.edited",
        "itemgroup.deleted",
      ],
      type:null,
      secretKey:false,
      valid:true,
    }
  },
  computed:{
  },
  methods: {
    saveData() {

      if(!this.$refs.webhooksForm.validate()){
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.WEBHOOKS.CREATE, {
        name: this.name,
        type: this.type,
        secret_key:this.secretKey,
        target:this.target,
      }).then((res) => {
        if (res.data.status==="SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.webhooksForm.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.secretKey = randomString.generate(32);
  }
}
</script>

<style scoped>

</style>