<template>
  <div>
    <page-title :heading="'Webhooks'" :subheading="$t('generic.lang_create') " :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <CreateWebhooksComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import CreateWebhooksComponent from "../../../../components/settings/moduleSettings/webhooks/CreateWebhooksComponent";

export default {
  components: {
    CreateWebhooksComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>